<template>
  <div class="Content" v-html="getContent(1235)"></div>
</template>

<script>
import { loadData } from "@/utils";

export default {
  data() {
    return {
      text: [],
      myLanguage: this.$router.currentRoute.params.language.toUpperCase(),
    };
  },
  methods: {
    getContent: function (key) {
      return this.text[key];
    },
  },
  async mounted() {
    this.text = await loadData("Privacy", this.myLanguage);
  },
  name: "Privacy",
};
</script>
<style scoped>
.Content {
  margin: 2em;
  text-align: left;
}
</style>
